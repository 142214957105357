import { NewsHeaders, NewsType } from "@/models/news";
import { List, ListQueryParams } from "@/models/table";
import axios from "axios";

export const getNews = async <T>(id: number, stockNews: boolean, params = {}, signal?: AbortSignal) => {
    try {
        const res = await axios.get<T>(`/API/v2/${stockNews ? "id/" : ""}${id}/news_data`, {
            params: {
                max_result: 50,
                ...params,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const newGetNews = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, next_page_url, id, data } = params;
    try {
        const options = { ...filter_options.data, ...data };
        const isStockNews = options.stock_news;
        delete options.stock_news;

        const url = next_page_url || `/API/v2/${!!isStockNews ? "id/" : ""}${id}/news_data`;
        const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, min_impact: 7, ...options };

        const res = await axios.get<List<NewsType, NewsHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getDataGridNews = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, id, data, next_page_url, universe_id } = params;
    try {
        const { type, stockFilter, ...newData } = data;
        const { customFilterModel, ...newParams } = filter_options;

        const url = next_page_url || `/interface/api/news/${type}/${id != undefined ? id : universe_id}/list`;
        const defaultParams = next_page_url
            ? {}
            : {
                  page_size,
                  page,
                  ...filter_options,
                  ...newParams,
                  ...newData,
                  size: 500,
                  customFilterModel: { items: [...(customFilterModel?.items ?? []), stockFilter && { columnField: "stock", id: 0, operatorValue: "equals", value: universe_id }].filter(Boolean) },
              };

        const res = await axios.get<List<NewsType, NewsHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        data.stockFilter &&
            res.data["columns"].forEach((item) => {
                if (item.field === "stock") {
                    item.filterable = false;
                }
            });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getDataGridNewDetail = async (id: string) => {
    try {
        const res = await axios.get(`/interface/api/news/id/${id}`);

        return res.data;
    } catch (err) {
        throw err;
    }
};
