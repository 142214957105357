import { FC, useEffect, useState } from "react";
import { Skeleton as MUISkeleton, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { textColorState } from "@/lib/store";

interface PropTypes {
    variant: "text" | "rectangular" | "rounded" | "circular";
    width: string;
    height: string;
    loading: boolean;
    // data param: To implement it we should wait until all API calls are made asynchronously.
    data?: boolean;
    fontSize?: string;
}

const Skeleton: FC<PropTypes> = ({ variant, width, height, loading, data = true, fontSize = "1.5rem" }) => {
    const [firstMessage, setFirstMessage] = useState(false);
    const textColor = useRecoilValue(textColorState);

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (loading) {
                setFirstMessage(true);
            }
        }, 10000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        loading &&
        (!firstMessage ? (
            <MUISkeleton variant={variant} width={width} height={height} />
        ) : (
            <>
                <MUISkeleton variant={variant} width={width} height={height} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography style={{ display: "flex", justifyContent: "center", alignItems: "center", visibility: "visible", fontSize: fontSize, color: textColor }}>{!data ? "Not Available…" : "Please wait…"}</Typography>
                </MUISkeleton>
            </>
        ))
    );
};

export default Skeleton;
